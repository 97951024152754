* {
  box-sizing: border-box;
}

html,
body,
.container,
.grid-container {
  height: 100vh;
  margin: 0;
  position: relative;
}

.grid-container * {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "a c c f" "a e d d" "b b d d";
  grid-gap: 10px 10px;
}

.a { grid-area: a; }

.b { grid-area: b; }

.c { grid-area: c; }

.d { grid-area: d; }

.e { grid-area: e; }

.f { grid-area: f; }

.slogan {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, .8);
  padding: .25em .5em;
  text-align: center;
  width: 100%;
  height: auto;
  font-size: 32px;
  text-transform: uppercase;
  font-weight: bold;
  color: #c32121cc;
  transform: translateY(-50%);
}

.slogan__progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 255, 0, .3);
}

.slogan__progress:after {
  content: attr(data-progress) ' %';
  font-size: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: black;
}

.slogan small {
  font-size: 16px;
  color: black;
}
